<script lang="ts">
	import { userinput } from "../services/stores";

	export let showHintsVisible = false;

	export let onEnter: () => void;

	export let showHints: () => void;

	let hint = "";

	// function showHint() {
	// 	hint = "answer";
	// }

	function on1button() {
		$userinput += "1";
	}
	function on2button() {
		$userinput += "2";
	}
	function on3button() {
		$userinput += "3";
	}
	function on4button() {
		$userinput += "4";
	}
	function on5button() {
		$userinput += "5";
	}
	function on6button() {
		$userinput += "6";
	}
	function on7button() {
		$userinput += "7";
	}
	function on8button() {
		$userinput += "8";
	}
	function on9button() {
		$userinput += "9";
	}
	function on0button() {
		$userinput += "0";
	}
	function onCommabutton() {
		$userinput += ".";
	}
	function onClear(event: MouseEvent & { currentTarget: EventTarget & HTMLButtonElement }) {
		if ($userinput.length > 0) {
			$userinput = $userinput.substring(0, $userinput.length - 1);
		}
	}
</script>

<div class="flex-box1">
	<div class="grid-container">
		<button on:click={on7button} class="grid-item7 custom-button">7</button>
		<button on:click={on8button} class="grid-item8 custom-button">8</button>
		<button on:click={on9button} class="grid-item9 custom-button">9</button>
		<div>
			{#if showHintsVisible}
				<button id="showhint" on:click={showHints}>❔</button>
			{/if}
		</div>
		<button on:click={on4button} class="grid-item4 custom-button">4</button>
		<button on:click={on5button} class="grid-item5 custom-button">5</button>
		<button on:click={on6button} class="grid-item6 custom-button">6</button>
		<button on:click={on1button} class="grid-item1 custom-button">1</button>
		<button on:click={on2button} class="grid-item2 custom-button">2</button>
		<button on:click={on3button} class="grid-item3 custom-button">3</button>
		<button on:click={on0button} class="grid-item-zero custom-button">0</button>
		<button on:click={onCommabutton} class="grid-item-comma custom-button">,</button>
		<button on:click={onEnter} class="grid-item-enter custom-button">Enter</button>
		<button on:click={onClear} class="grid-item-clear custom-button">C<span class="tooltip">Clear</span></button>
	</div>
</div>
<div class="hint-display">
	{#if hint}
		<p>{hint}</p>
	{/if}
</div>

<style>
	.hint-display {
		margin-left: 20px;
		font-size: 1.2em;
		color: #007bff;
	}
	.custom-button {
		background: #ffffff7c;
		border: 2px solid white;
		color: #fff;
		cursor: pointer;
		transition: box-shadow 0.2s ease;
	}

	.custom-button:hover {
		animation: glow-border 0.5s infinite alternate;
	}
	@keyframes glow-border {
		0% {
			border-color: white;
			box-shadow: 0 0 10px white;
		}
		100% {
			border-color: white;
			box-shadow: 0 0 20px white;
		}
	}

	.flex-box1 {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	button {
		width: 75px;
		height: 75px;
		margin: 5px;
		border-radius: 10px;
	}
	.grid-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(4, 1fr);
		font-size: 40px;
		justify-content: center;
		align-items: center;
		padding-top: 5px;
	}
	.grid-item7 {
		grid-column: 1/2;
		grid-row: 1/2;
	}

	.grid-item8 {
		grid-column: 2/3;
		grid-row: 1/2;
	}

	.grid-item9 {
		grid-column: 3/4;
		grid-row: 1/2;
	}

	.grid-item4 {
		grid-column: 1/2;
		grid-row: 2/3;
	}

	.grid-item5 {
		grid-column: 2/3;
		grid-row: 2/3;
	}

	.grid-item6 {
		grid-column: 3/4;
		grid-row: 2/3;
	}

	.grid-item1 {
		grid-column: 1/2;
		grid-row: 3/4;
	}

	.grid-item2 {
		grid-column: 2/3;
		grid-row: 3/4;
	}

	.grid-item3 {
		grid-column: 3/4;
		grid-row: 3/4;
	}

	.grid-item-enter {
		grid-column: 4/5;
		grid-row: 3/5;
		height: 160px;
		font-size: 20px;
	}

	.grid-item-zero {
		grid-column: 1/3;
		grid-row: 4/5;
		width: 160px;
	}

	.grid-item-comma {
		grid-column: 3/4;
		grid-row: 4/5;
	}
	.grid-item-clear {
		grid-column: 4/5;
		grid-row: 2/2;
		position: relative;
		padding: 10px;
		cursor: pointer;
	}
	.grid-item-clear .tooltip {
		visibility: hidden;
		width: 60px;
		background-color: none;
		color: #fff;
		text-align: center;
		font-size: 18px;
		padding: 5px;
		position: absolute;
		bottom: 110%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		transition:
			visibility 0s linear 0.5s,
			opacity 0.5s ease;
	}
	.grid-item-clear .tooltip::after {
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
	}
	.grid-item-clear:hover .tooltip {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.9s;
	}
</style>
