import { fetchGet } from "./fetch";
import { UtcResponse } from "../models/interfaces";


export class DateUtc {
    private offset: number; // Offset from system clock to server UTC
    private lastSync: number; // Timestamp of the last server sync in milliseconds
    private syncInterval: number; // Interval for syncing with the server in milliseconds

    //We just need it when we start,  I guess
    constructor(syncInterval: number = 60000*10) { // Default sync every 60*10 seconds
        this.offset = 0;
        this.lastSync = Date.now();
        this.syncInterval = syncInterval;
        this.initializeSync();
    }

    public static utcNow(): number {
        const instance = DateUtc.getInstance();
        return Date.now() + instance.offset;
    }

    // Sync the client clock with the server's UTC time
    // For now, we don't use this
    private async syncWithServer(): Promise<void> {
        try {
            // let res = await fetchGet<UtcResponse>('/BatchCrammer/GetUtcNow')
            let res = await fetchGet<UtcResponse>('/BatchCrammer/GetUtcNow',false)

            const now = Date.now();
            if (res.utcNow) {
                const serverTime = new Date(res.utcNow).getTime();
                this.offset = serverTime - now;
                this.lastSync = now;
                //console.log(`Clock synchronized. Offset: ${this.offset} ms`);
            }
            else {
                this.offset=0;
                throw "No utc time from the server";
            }
        } catch (error) {
            console.error("Failed to sync with server UTC:", error);
        }
    }

    // Get the current UTC time as an ISO string
    public getCurrentUtcString(): string {
        return new Date(Date.now() + this.offset).toISOString();
    }
    // public getCurrentUtc(): string {
    //     return new Date(Date.now() + this.offset).toISOString();
    // }

    // Initialize synchronization logic
    private initializeSync(): void {
        this.syncWithServer();
        setInterval(() => {
            this.syncWithServer();
        }, this.syncInterval);
    }

    // Static instance for the singleton pattern
    private static instance: DateUtc | null = null;

    public static getInstance(syncInterval: number = 60000): DateUtc {
        if (!DateUtc.instance) {
            DateUtc.instance = new DateUtc(syncInterval);
        }
        return DateUtc.instance;
    }
}

// Export the singleton instance
export const dateUtc = DateUtc.getInstance();
