<script lang="ts">
  //App.svelte
  import "../global.css";
  import Login from "../User/Login.svelte";
  import { Router, Route, Link, navigate } from "svelte-routing";
  import Signup from "../User/Signup.svelte";
  import Landing from "../Landing/Landing.svelte";
  import Keypad from "../Keypad/Keypad.svelte";
  import Keypadmem from "../Keypadmem/Keypadmem.svelte";
  import Home from "../Home/Home.svelte";
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import PrivateRoute from "./PrivateRoute.svelte";
  import Menu from "./Menu.svelte";
  import Profile from "../User/Profile.svelte";
  import Achievement from "../User/Achievements.svelte";
  import Stat from "../User/Statistics.svelte";
  import Strike from "../Remove/Strike.svelte";
  import ConnectionAlert from "../ConnectionAlert.svelte";
  import Admin from "../Admin.svelte";
  import { isMobile, isLoading, newEvent, showDailyLimitAlert, isAuthenticated, hasSubscription, onLoginPage } from "../services/stores";
  import { PollPaid } from "../services/stores";

  import FibonacciSpinner from "../loader/FibonacciSpinner.svelte";
  import Demo from "../loader/Demo.svelte";
  import Appliedcreate from "../Appliedmath/AppliedCreate.svelte";
  import Appliedsolve from "../Appliedmath/Appliedmathsolve.svelte";
  import AppliedSelection from "../Appliedmath/AppliedSelection.svelte";
  import Accomplished from "../User/Accomplished.svelte";
  import { fetchGet, fetchPost } from "../services/fetch";
  import Problemlist from "../Appliedmath/Problemlist.svelte";
  import Charid from "../Appliedmath/Charid.svelte";
  import Sounds from "../User/Settings.svelte";
  import Email from "../Email.svelte";

  //svelte fromvue
  //import { Router, Route } from 'svelte-routing';

  // import ReactPayment from "./Payment/svelte/fromreact/ReactPayment.svelte";
  import HtmlApp from "../Payment/HtmlApp.svelte";
  import HtmlReturn from "../Payment/HtmlReturn.svelte";
  import InfiniteButton from "../Payment/InfiniteButton.svelte";
  import DailyLimitAlert from "../Payment/CurrentLimit.svelte";
  import SupportButton from "../SupportButton.svelte";
  import A from "../instructions/memory/buttons/a.svelte";
  import A5Squared from "../instructions/mental/A5_squared.svelte";

  import { UserService } from "../services/user.service";
  import { SettingsVm, ContactVm } from "../models/interfaces";
    // import AvailableMemory from "../Home/AvailableMemory.svelte";

  const options = {};

  // This code could be placed in main.ts or in an initial loading component
  document.addEventListener("DOMContentLoaded", () => {
    isMobile.set(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  });

  async function AcknowledgeAccomplishment() {
    var url = "AppliedMath/AcknowledgeAccomplishment";
    await fetchPost<void, string>(url, $newEvent);
    newEvent.set("");
  }

  let showPaymentButton = true;

  //only show the payement butt if we are logged in
  //AND we don't have a subscription
  $: showPaymentButton = !$hasSubscription && $isAuthenticated && !$onLoginPage;

  //Maybe poll, if we have paid
  //Then we load settings, and set the hasSubscription, if we have paid
  //TODO We could do this manually by go into the settings page.

  async function pollPaymentStatus() {
    let url = "/User/GetSettings";
    let settings = await fetchGet<SettingsVm>(url);
    $hasSubscription = settings.hasPaid;

    if ($hasSubscription) {
      $PollPaid = false;
      //We need to update the user that is stored in the client

      let loggedInUser = localStorage.getItem("user");
      console.log(`******user:${loggedInUser}`);
      if (loggedInUser) {
        let user: ContactVm = JSON.parse(loggedInUser);
        user.hasSubscription = $hasSubscription;
        localStorage.setItem("user", JSON.stringify(user));
      }
    }
  }

  let pollIntervalId = 0;

  $: if ($PollPaid) {
    pollIntervalId = setInterval(async () => {
      await pollPaymentStatus();
    }, 3000) as unknown as number;
  }

  // Stop the interval when PollPaid becomes false
  $: if (!$PollPaid) {
    clearInterval(pollIntervalId);
  }

  //$: {
  //console.log(`hasSubscription=${$hasSubscription}`);
  //console.log(`isAuthenticated=${$isAuthenticated}`);
  //console.log(`onLoginPage=${$onLoginPage}`);
  //console.log(`showPaymentButton=${showPaymentButton}`);
  //}

  // $: { //when logged in, we get a hasSubscriptin in the contactvm
  //   if($isAuthenticated) {
  //     //var hasValid = HasValidSubscription();
  //     //hasSubscription = hasValid;
  //     //UserService.
  //     $hasSubscription = UserService.hasSubscription();
  //   }
  //}
</script>

<SvelteToast {options} />

{#if $showDailyLimitAlert}
  <DailyLimitAlert></DailyLimitAlert>
{/if}

<div class="container-flex">
  <Menu hamburger={true}></Menu>
  {#if showPaymentButton}
    <InfiniteButton></InfiniteButton>
  {/if}
  <SupportButton></SupportButton>
  <Profile></Profile>
</div>

{#if $newEvent}
  <div id="popup-overlay" class="hidden">
    <div id="popup">
      <!-- <h1>New event: {$newEvent}</h1>  -->
      <Accomplished></Accomplished>
      <button on:click={AcknowledgeAccomplishment} id="close-button">Continue</button>
    </div>
  </div>
{/if}

<ConnectionAlert></ConnectionAlert>

<div class="overlay" style="display: {$isLoading ? 'block' : 'none'};">
  <div class="overlay-canva" style="width: 300px; height: 300px; margin: 0; z-index: 1000;">
    <FibonacciSpinner canvasid="fibId"></FibonacciSpinner>
  </div>
</div>

<Router on:routeLoaded={() => alert("routeLoaded")}>
  <main>
    <Route path="/login" component={Login} />
    <Route path="/login/:slug" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/landing" component={Landing} />
    <Route path="/demo" component={Demo} />

    <Route path="/support" component={Email} />
    <!-- <Route path="/" component={Home} /> -->
    <Route path="/" component={Home} />

    <Route path="/achievement" component={Achievement} />
    <Route path="/statistic" component={Stat} />
    <Route path="/Strike" component={Strike} />

    <PrivateRoute path="/settings" component={Sounds} />
    <PrivateRoute path="/charid" component={Charid} />
    <PrivateRoute path="/problemlist" component={Problemlist} />
    <!-- <PrivateRoute path="/available" component={AvailableMemory} /> -->

    <PrivateRoute path="/createapplied" component={Appliedcreate} />
    <PrivateRoute path="/applied/:charid/edit" component={Appliedcreate} />

    <PrivateRoute path="/appliedselection" component={AppliedSelection} />
    <PrivateRoute path="/appliedmath" component={Appliedsolve} />
    <PrivateRoute path="/applied/:slug" component={Appliedsolve} />

    <!-- <PrivateRoute path="/keypadeq" component={KeypadEq} />
    <PrivateRoute path="/keypadeq/:slug" component={KeypadEq} /> -->
    <!-- <PrivateRoute path="/appliedmath/:slug" component={Appliedsolve} /> -->
    <Route path="/home" component={Home} />
    <PrivateRoute path="/mental/:slug" component={Keypad} />
    <PrivateRoute path="/keypadmem/:slug" component={Keypadmem} />
    <PrivateRoute path="/admin" component={Admin} />

    <!-- <Route path="/payment" component={Payment} /> -->

    <!-- <Route path="/payment/fromvue/" component={SrCheckoutForm} />
    <Route path="/payment/fromvue/return" component={SrReturn} />

    <Route path="/payment/fromreact" component={ReactPayment} />
    <Route path="/completion" component={ReactCompletion} /> -->

    <Route path="/payment" component={HtmlApp} />
    <Route path="/return" component={HtmlReturn} />
  </main>
</Router>

<style>
  :global(.error) {
    --toastBackground: red;
    --toastBarBackground: white;
    --toastWidth: 400px;
  }
  :global(.success) {
    --toastBackground: #008800;
    --toastBarBackground: #1010aa;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 1500px;
    height: 1500px;
    background-color: rgba(0, 0, 0, 0.779);
    z-index: 999;
    justify-content: center;
    align-items: center;
  }
</style>
