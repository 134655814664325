// user.service.ts
import { authHeader } from './../services/auth-header';
import { Hash } from '../services/hash2';
import { isAuthenticated, hasSubscription } from "./stores";
import { fetchPost } from "./fetch";
import { ContactVm } from "../models/interfaces";

export class UserService {



	async login(name: string, unencryptedPpassword: string): Promise<ContactVm> {
		const hash = new Hash();
		const password = hash.encrypt(unencryptedPpassword);
		
		try {
			const response = await fetchPost<ContactVm, any>("login/authenticate", { name, password });
			hasSubscription.set(response.hasSubscription);
			//console.log(`****user.service setting hasSubscription to ${response.hasSubscription}`);

			localStorage.setItem('user', JSON.stringify(response));
			//isAuthenticated.set(true); checking the token first
			return response;

		} catch (error) {
			console.error('Login error:', error);
			throw error;
		}
	}


	logout() {
		// remove user from local storage to log user out
		localStorage.removeItem('user');
		isAuthenticated.set(false);
	}

	
	getAll() {
		const requestOptions = {
			method: 'GET',
			headers: authHeader(),
			//header:{  },
			body: null
		};

		return fetch(`api/users`,
			requestOptions)
			.then(this.handleResponse);
	}


	getById(id: number) {
		const requestOptions = {
			method: 'GET',
			headers: authHeader()
		};

		return fetch(`api/users/${id}`,
			requestOptions).then(this.handleResponse);
	}

	update(user: Contact) {
		const requestOptions = {
			method: 'PUT',
			headers: { ...authHeader(), 'Content-Type': 'application/json' },
			body: JSON.stringify(user)
		};

		return fetch(`api/users/${user.Id}`,
			requestOptions).then(this.handleResponse);
	}

	// prefixed function name with underscore because delete is a reserved word in javascript
	_delete(id: number) {
		const requestOptions = {
			method: 'DELETE',
			headers: authHeader()
		};

		return fetch(`api/users/${id}`,
			requestOptions).then(this.handleResponse);
	}

	async handleResponse(response: Response): Promise<any> {
		const text = await response.text();
		const data = text ? JSON.parse(text) : null;

		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				this.logout();
				location.reload();
			}

			const error = (data && (data.Message || data.error)) || response.statusText;
			throw new Error(error);
		}

		return data;
	}

	static isLoggedIn(): boolean {
		const loggedIn = localStorage.getItem('user');
		//console.log(`******user:${loggedIn}`)
		if (loggedIn) {
			let user: ContactVm = JSON.parse(loggedIn);
			hasSubscription.set(user.hasSubscription);
			return true;
		}
		else
			return false;
	}

	static getDisplayName(): string {
		const loggedIn = localStorage.getItem('user');
		if (loggedIn) {
			var user = JSON.parse(loggedIn);
			return user.name;
		}
		else
			return "";
	}
}


interface Contact {
	Id: number
	Name: string;
	Address: string;
	Address2: string;
	City: string;
	Subregion: string;
	PostalCode: string;
	Phone: string;
	Email: string;
}
