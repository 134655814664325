<script lang="ts">
    import { navigate } from "svelte-routing";
    import { toast } from "@zerodevx/svelte-toast";
    import { onMount, onDestroy } from "svelte";
    import { fetchGet, fetchPost } from "../services/fetch";
    import type { SvelteComponent } from "svelte";
    import { newEvent } from "../services/stores";
    import { updateRating } from "../services/Contact";
    //import { AppliedQuestionClientVm } from "./AppliedQuestionVm";
    import { StoreAppliedQuestionVm } from "../models/interfaces";
    import AppliedImage from "./AppliedImage.svelte";

    import "./appliedmath.css";

    export let slug: any;

    //let availableHints=10;

    let hint1 = "";
    let hint2 = "";
    let hint3 = "";
    let hintsShown = 0;

    console.log("appliedsolve loaded2c");
    let steps = 0;
    let questionVm: StoreAppliedQuestionVm | null = null;

    $: load();

    async function load() {
        var clientUrl = `/AppliedMath/GetProblem/Rand`;

        if (slug) {
            clientUrl = `/AppliedMath/GetProblem/${slug}`; //TODO it's outdated, using charid now
        }

        questionVm = await fetchGet<StoreAppliedQuestionVm>(clientUrl);
        steps = questionVm.stepCount;
    }

    export class SolveVm {
        questionId: number = 0;
        x: number = 0;
        y: number = 0;
        left: number = 0;
        right: number = 0;
        top: number = 0;
        bottom: number = 0;
    }

    export class SolveResult {
        points: number = 0;
        totalPoints: number = 0;
        events: string[] = [];
    }

    //This is sent to the AppliedImage
    async function clickHandler(x: number, y: number, left: number, right: number, top: number, bottom: number) {
        console.log(`A onClick ${x} ${y} ${left} ${right} ${top} ${bottom}`);

        let solveVm = new SolveVm();
        solveVm.x = x;
        solveVm.y = y;
        solveVm.left = left;
        solveVm.right = right;
        solveVm.top = top;
        solveVm.bottom = bottom;

        //Some debugging
        let percentX = (x - left) / (right - left);
        let percentY = (y - top) / (bottom - top);
        console.log(`percentX=${percentX} percentY = ${percentY}`);

        var url = "AppliedMath/TrySolve";
        var solveResult = await fetchPost<SolveResult, SolveVm>(url, solveVm);

        console.log("Point" + solveResult.points);
        console.log("Total" + solveResult.totalPoints);

        console.log("call updateRating");

        updateRating();
        console.log("updatRating has been called");

        if (solveResult.points > 0) {
            toast.push("Correct", {
                classes: ["success"],
                pausable: true,
            });

            // solveResult.events.forEach((e) => {
            //     console.log(e);

            //     newEvent.set(e);
            // });

            //Get a new one or show hints
            load();
        } else {
            console.log("C10");
            if (questionVm && hintsShown < questionVm.stepCount) {
                let nextHintNumber = hintsShown + 1;
                toast.push(`Loading hint ${nextHintNumber}`, {
                    classes: ["error"],
                    initial: 0,
                    dismissable: true, // Adds a close button
                });
                await getNextHint();
            } else {
                toast.push("Sorry, wrong", {
                    classes: ["error"],
                    initial: 0,
                    dismissable: true, // Adds a close button
                });
                load();
            }
        }
        //load(); //we load it again?
    }

    async function getNextHint() {
        if (hintsShown == 0) {
            let response = await fetchGet<any>(`/AppliedMath/GetHint/1`);
            hint1 = response.hint;
        } else if (hintsShown == 1) {
            let response = await fetchGet<any>(`/AppliedMath/GetHint/2`);
            hint2 = response.hint;
        } else if (hintsShown == 2) {
            let response = await fetchGet<any>(`/AppliedMath/GetHint/3`);
            hint3 = response.hint;
        }
        hintsShown++;

        //We should tell the backend we displayed a hint, or even get the hint from the backend, instead of having it preloaded
    }
</script>

{#if questionVm}
    <AppliedImage base64Image={questionVm.encodedQuestionImage} onClick={clickHandler} {hint1} {hint2} {hint3}></AppliedImage>
    <div class="applied-content">
        <div class="applied-rating">Rating</div>
        <div class="button-hint">
            <button on:click={getNextHint} class="unique-button hint">Show Hint</button>
            <button class="unique-button hint">Next</button>
        </div>
    </div>
    <!-- {#if questionVm.stepCount >= 1}
        <img src={questionVm.encodedImageHint1} alt="sdf"/>
    {/if} -->
{/if}

<!-- questionVm -->
<style>
    /* @import "../global.css"; */
</style>
