<script lang="ts">
    import { tick } from "svelte";
    import type { MathNode, OperatorNode, FunctionNode, ParenthesisNode, SymbolNode, ConstantNode } from "mathjs";
    import { writable } from "svelte/store";

    export let eq = "";
    let mathViewContent = "";
    let convertToLatexError = false;
    let mathViewWritable = writable("");

    let parse: any;
    let simplify: any;

    async function loadMath() {
        const math = await import("mathjs");
        parse = math.parse;
        simplify = math.simplify;
    }

    loadMath().then(() => {
        // Now you can be sure that mathjs is loaded, and you can use parse and simplify globally
        console.log("Math.js has been loaded.");
    });

    $: if (eq) {
        processUserInput(eq);
    }

    // Type guard to check if a node is an OperatorNode
    function isOperatorNode(node: math.MathNode): node is math.OperatorNode {
        return node.type === "OperatorNode";
    }

    // Type guard to check if a node is a FunctionNode
    function isFunctionNode(node: math.MathNode): node is math.FunctionNode {
        return node.type === "FunctionNode";
    }

    // Type guard to check if a node is a ParenthesisNode
    function isParenthesisNode(node: math.MathNode): node is math.ParenthesisNode {
        return node.type === "ParenthesisNode";
    }

    // Function to canonicalize expressions by sorting operands of commutative operators
    function canonicalize(node: math.MathNode): math.MathNode {
        if (isOperatorNode(node)) {
            const operatorNode = node;
            // Recursively canonicalize the arguments
            operatorNode.args = operatorNode.args.map(canonicalize);

            // Check if the operator is commutative
            if (isCommutative(operatorNode)) {
                // Sort the arguments
                operatorNode.args.sort(compareNodes);
            }
        } else if (isFunctionNode(node)) {
            const functionNode = node;
            // Recursively canonicalize the arguments
            functionNode.args = functionNode.args.map(canonicalize);
        } else if (isParenthesisNode(node)) {
            const parenthesisNode = node;
            // Canonicalize the content inside parentheses
            parenthesisNode.content = canonicalize(parenthesisNode.content);
        }
        // For other node types (ConstantNode, SymbolNode, etc.), no action is needed
        return node;
    }

    // Function to determine if an operator is commutative
    function isCommutative(node: math.OperatorNode): boolean {
        return node.op === "+" || node.op === "*";
    }

    // Function to compare two nodes for sorting
    function compareNodes(a: math.MathNode, b: math.MathNode): number {
        const aKey = nodeKey(a);
        const bKey = nodeKey(b);
        if (aKey < bKey) return -1;
        if (aKey > bKey) return 1;
        return 0;
    }

    // Function to generate a unique key for each node for comparison
    function nodeKey(node: math.MathNode): string {
        if (node.type === "SymbolNode") {
            const symbolNode = node as math.SymbolNode;
            return "Symbol:" + symbolNode.name;
        } else if (node.type === "ConstantNode") {
            const constantNode = node as math.ConstantNode;
            return "Constant:" + constantNode.value;
        } else if (node.type === "OperatorNode") {
            const operatorNode = node as math.OperatorNode;
            const argsKeys = operatorNode.args.map(nodeKey).sort().join(",");
            return "Operator:" + operatorNode.op + ":" + argsKeys;
        } else if (node.type === "FunctionNode") {
            const functionNode = node as math.FunctionNode;
            const argsKeys = functionNode.args.map(nodeKey).sort().join(",");
            return "Function:" + functionNode.fn.name + ":" + argsKeys;
        } else if (node.type === "ParenthesisNode") {
            const parenthesisNode = node as math.ParenthesisNode;
            return "Parenthesis:" + nodeKey(parenthesisNode.content);
        } else {
            // For other node types, use their string representation
            return node.type + ":" + node.toString();
        }
    }

    function processUserInput(input: string) {
        try {
            let mathUser = simplifyExpression(input);
            let mathUserStr = mathUser.toString();

            let latex = convertToLatex(mathUserStr);
            if (latex != "error") {
                mathViewWritable.set(latex);
                convertToLatexError = false;

                ///await tick(); // Wait for DOM update //TODO Not necessary to do twice
                updateMathJax();
            } else {
                convertToLatexError = true;
            }

            console.log(input);
        } catch (error) {
            console.error(error);
        }
    }

    async function updateMathJax() {
        await tick();

        if (typeof window !== "undefined" && window.MathJax) {
            await window.MathJax.typesetPromise();
        }

    }

    function convertToLatex(input: string) {
        try {
            // Parse the input into an AST (Abstract Syntax Tree)
            const node = parse(input);

            // Generate LaTeX from the AST
            let latex = node.toTex();

            console.log("latex: ", latex);

            // Optionally wrap with delimiters for MathJax
            return `$$${latex}$$`;
        } catch (error) {
            console.error("Error parsing input:", error);
            //return "Waiting for legal input";
            return "error";
        }
    }

    function simplifyExpression(userExpression: string): MathNode {
        userExpression = userExpression.replace(/×/g, "*");
        userExpression = insertImplicitMultiplication(userExpression);
        const parsedUserExpr = parse(userExpression);
        const simplifiedUserExpr = simplify(parsedUserExpr);
        return canonicalize(simplifiedUserExpr);
    }

    function tokenize(expr: string): string[] {
        const tokens: string[] = [];
        const functions = ["sin", "cos", "tan", "ln", "log", "exp", "acos", "asin", "atan", "arcsin", "arccos", "arctan"];
        let i = 0;

        while (i < expr.length) {
            const char = expr[i];

            if (/\d/.test(char)) {
                // Number
                let num = char;
                i++;
                while (i < expr.length && /[\d\.]/.test(expr[i])) {
                    num += expr[i];
                    i++;
                }
                tokens.push(num);
            } else if (/[a-zA-Z]/.test(char)) {
                // Check for function names
                let foundFunction = false;
                for (let func of functions) {
                    const funcLength = func.length;
                    const substring = expr.substring(i, i + funcLength);
                    if (substring === func) {
                        tokens.push(func);
                        i += funcLength;
                        foundFunction = true;
                        break;
                    }
                }
                if (!foundFunction) {
                    // Treat individual letters as variables
                    tokens.push(char);
                    i++;
                }
            } else if (char === "(" || char === ")") {
                tokens.push(char);
                i++;
            } else if (/\s/.test(char)) {
                // Skip whitespace
                i++;
            } else {
                // Operator or other character
                tokens.push(char);
                i++;
            }
        }
        return tokens;
    }

    function isOperand(token: string, functions: string[]): boolean {
        if (/\d+(\.\d+)?/.test(token)) {
            // Number
            return true;
        } else if (/[a-zA-Z]+/.test(token)) {
            // Variable (exclude functions)
            return !functions.includes(token);
        } else {
            return false;
        }
    }

    function isFunction(token: string, functions: string[]): boolean {
        return functions.includes(token);
    }

    function insertImplicitMultiplication(expr: string): string {
        const functions = ["sin", "cos", "tan", "ln", "log", "exp", "acos", "asin", "atan", "arcsin", "arccos", "arctan"];
        const tokens = tokenize(expr);
        const outputTokens: string[] = [];

        for (let i = 0; i < tokens.length; i++) {
            const currentToken = tokens[i];
            outputTokens.push(currentToken);

            if (i < tokens.length - 1) {
                const nextToken = tokens[i + 1];

                const currentIsOperand = isOperand(currentToken, functions);
                const nextIsOperand = isOperand(nextToken, functions);

                const needMultiplication =
                    (currentIsOperand && nextIsOperand) ||
                    (currentIsOperand && nextToken === "(") ||
                    (currentToken === ")" && (nextIsOperand || isFunction(nextToken, functions) || nextToken === "("));

                if (needMultiplication) {
                    outputTokens.push("*");
                }
            }
        }

        return outputTokens.join("");
    }
</script>

<div>
    <div class="small-font">{@html $mathViewWritable}</div>
</div>
