<script lang="ts">
    export let example1 = "";
    export let example2 = "";
    export let example3 = "";

    export let onStartLearning: () => void;
    export let onStartTimedTest: () => void;

    export let startOrContinue="Start";
</script>

<div class="testpopup-overlay" id="testpopup">
    <div class="testInro-popup">
        <h2>Take the timed test?</h2>
        <p>The questions will be of this type:</p>
        <div class="pop-question">
            <ul>
                <li>{example1}</li>
                <li>{example2}</li>
                <li>{example3}</li>
            </ul>
        </div>
        <!-- <p><strong>4×7 9×3 6×8</strong></p> -->
        <div class="bulleted-content">
                <span>If you think you know some of these, then it is smart to take the timed test.</span>
                <span>If there are some questions you don’t know, click the “?”-button.</span>
                <span>The questions you get wrong or click “?” on will go to the staging area, for you to learn later.</span>
                <span>
                    If you choose to skip the timed test, all problems will go to the staging area, and it will take longer time to finish
                    this level.
                </span>
        </div>
    </div>
    <div class="testintro-button">
        <button id="time-test" on:click={onStartTimedTest}>{startOrContinue} timed test</button>
        <button id="start-learning" on:click={onStartLearning}>Start learning</button>
    </div>
</div>

<style>
    p,
    h2 {
        margin: 10px;
    }
    .testpopup-overlay {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.826);
        justify-content: center;
        align-items: center;
        z-index: 20000;
    }

    .testInro-popup {
        background-color: rgba(0, 0, 0, 0.832);
        padding: 20px;
        border-radius: 10px;
        width: 100%;
        max-width: 500px;
        text-align: center;
    }

    .testInro-popup h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .testInro-popup p {
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 20px;
    }
    .testintro-button {
        display: flex;
    }
    .pop-question {
        margin-top: -15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .bulleted-content span {
        display: flex;
        padding: 5px;
        text-align: left;
        margin: 0;
    }
</style>
