<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import { loadStripe } from "@stripe/stripe-js"; // Import loadStripe as it's a function
  import type { Stripe } from "@stripe/stripe-js"; // Import Stripe as a type
  import { fetchGet, fetchPost } from "../services/fetch";
  import { Contact, ContactVm } from "./../models/interfaces";
  import { showDailyLimitAlert, showInfinitePlayButton } from "../services/stores";

  //  import { userInfo } from "os";

  let stripe: Stripe | null = null;
  let elements: any;
  let submitted = false;
  let clientSecret = "";
  let messages = "";

  let isLoaded = false;

  //$: console.log("Submitted " + submitted);

  onDestroy(() => {
    $showInfinitePlayButton = false;
  });
  onMount(async () => {
    $showInfinitePlayButton = true;
    $showDailyLimitAlert = false;
    let contact: Contact = await fetchGet<Contact>("/user/getUser");

    const config = await fetchGet<{ publishableKey: string }>("/config");
    if (!config.publishableKey) {
      addMessage("No publishable key returned from the server.");
      return;
    }

    stripe = await loadStripe(config.publishableKey);

    if (!stripe) {
      addMessage("Stripe failed to load.");
      return;
    }

    const paymentIntent = await fetchPost<{ clientSecret: string }, void>("/create-payment-intent", undefined);
    clientSecret = paymentIntent.clientSecret;

    if (clientSecret && stripe) {
      elements = stripe.elements({
        clientSecret,
        appearance: {
          theme: "night",
          variables: {
            colorText: "#ffffff",
            colorPrimary: "#ffb700",
            fontFamily: "Raleway, sans-serif",
            spacingUnit: "2px",
            borderRadius: "4px",
          },
        },
      });

      const paymentElement = elements.create("payment");
      const linkAuthenticationElement = elements.create("linkAuthentication", {
        defaultValues: {
          email: contact.email,
        },
      });

      paymentElement.mount("#payment-element");
      linkAuthenticationElement.mount("#link-authentication-element");

      // Wait for both elements to be fully loaded
      paymentElement.on("ready", checkLoaded);
      linkAuthenticationElement.on("ready", checkLoaded);
    }
  });

  const checkLoaded = () => {
    // Once both elements are ready, set isLoaded to true
    if (document.querySelector("#payment-element") && document.querySelector("#link-authentication-element")) {
      isLoaded = true;
    }
  };
  const handleSubmit = async (event: Event) => {
    console.log("handleSubmit");
    messages = "";

    event.preventDefault();
    if (submitted) return;
    submitted = true;

    if (!stripe) {
      addMessage("Stripe is not initialized");
      return;
    }

    console.log("confirmPayment");
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/return`,
      },
    });

    if (error) {
      addMessage(error.message ?? "An unknown error occurred.");
      submitted = false;
    }
  };

  const addMessage = (message: string) => {
    messages = `> ${message}<br>`;
    console.log(`Debug: ${message}`);
  };
</script>

<main>
  <h2>One Year of Unlimited Access!</h2>
  <h4>One-time payment of €4 for 1 year of unlimited play.</h4>
  <p>No hidden fees, and no additional taxes from us.</p>
  <h4>No automatic renewals – pay once, enjoy for a full year.</h4>
  <p>Extend your access for more years at any time, whenever it suits you.</p>
  <form id="payment-form" on:submit={handleSubmit}>
    <div id="link-authentication-element"></div>
    <div id="payment-element"></div>
    {#if isLoaded}
      <button disabled={submitted}>Pay now</button>
    {:else}
      <button disabled={true}>Loading</button>
    {/if}
    <div id="error-message"></div>
  </form>

  <!-- {#if messages}
    <div id="messages" innerHTML={messages}></div>
  {/if} -->

  {#if messages}
    <div id="messages">
      {@html messages}
    </div>
  {/if}
</main>

<!-- This probably loads stripe even if we don't need it now -->
<svelte:head>
  <script src="https://js.stripe.com/v3/"></script>
</svelte:head>

<!-- alternative:
onMount(() => {
  const stripeScript = document.createElement("script");
  stripeScript.src = "https://js.stripe.com/v3/";
  stripeScript.async = true;

  stripeScript.onload = () => {
    console.log("Stripe.js dynamically loaded");
    // You can safely initialize Stripe here
  };

  document.head.appendChild(stripeScript);
}); -->


<style>
  :root {
    --light-grey: #f6f9fc;
    --dark-terminal-color: #0a2540;
    --accent-color: #635bff;
    --radius: 3px;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  button {
    background: none;
    border-radius: var(--radius);
    color: white;
    border: 1px solid rgb(255, 255, 0);
    margin: 10px auto;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
  }
  /* button:hover {
    filter: contrast(115%);
    color: black;
    background: linear-gradient(to bottom, rgba(255, 183, 0, 0.269), yellow);
  } */
  button:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }
  button:disabled {
    opacity: 0.5;
    cursor: none;
  }
  h2,
  h4 {
    margin: 0;
    padding-bottom: 0;
    color: rgba(255, 255, 0, 0.824);
    z-index: 0;
  }
  h2 {
    margin: 0 0 20px 0;
  }
  p {
    font-size: 10px;
  }

  /* a {
    color: var(--accent-color);
    font-weight: 900;
  } */

  #payment-form {
    width: 500px;
    border: yellow solid 1px;
    border-radius: var(--radius);
    padding: 20px;
    box-shadow:
      0 30px 50px -20px rgb(50 50 93 / 25%),
      0 30px 60px -30px rgb(0 0 0 / 30%);
  }

  #messages {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
    background-color: #0a253c;
    color: #fe87a1;
    padding: 20px;
    margin: 20px 0;
    border-radius: var(--radius);
    font-size: 0.7em;
  }
  @media (max-width: 540px) {
    #payment-form {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  /* .pay-btn:disabled {
    background-color: #333; 
    color: #fff; 
    opacity: 1; 
    cursor: not-allowed; 
    pointer-events: none; 
  } */
</style>
