<script lang="ts">
    import Accomplished from "./Accomplished.svelte";
    import { getProgressVm, getHomeTasksVm } from "../services/Contact";

    let ranking = 0;
    let problemsSolved = 0;
    let mentalMathLevel = 0;
    let memoryMathLevel = 0;
    let appliedMathLevel = 0;

    getProgress();

    async function getProgress() {
        let progress = await getProgressVm();

        let introProgress = progress.introProgress;

        memoryMathLevel = progress.memoryProgress;
        mentalMathLevel = progress.mentalMathProgress;
        appliedMathLevel = progress.appliedMathProgress;
        problemsSolved = progress.problemsSolved;

        let tasks = await getHomeTasksVm();
        ranking = tasks.rating;
    }
</script>

<div class="achievements">
    <h1>Statistic</h1>
    <div class="achievement">
        <div class="text">
            <div class="heading">Rating:</div>
            <div class="child-text" style="font-size: 20px;">{ranking}</div>
        </div>
    </div>
    <div class="achievement">
        <div class="text">
            <div class="heading" id="heading-problem">Problems Solved:</div>
            <div class="child-text" id="problem-solved" style="font-size: 20px;">{problemsSolved}</div>
        </div>
    </div>
    <div class="achievement">
        <div class="text">
            <div class="heading">Mental Math Level:</div>
            <div class="child-text" style="font-size: 20px;">{mentalMathLevel}</div>
        </div>
    </div>
    <div class="achievement">
        <div class="text">
            <div class="heading">Memory Math Level:</div>
            <div class="child-text" style="font-size: 20px;">{memoryMathLevel}</div>
        </div>
    </div>
    <div class="achievement">
        <div class="text">
            <div class="heading">Applied Math Level:</div>
            <div class="child-text" style="font-size: 20px;">{appliedMathLevel}</div>
        </div>
    </div>
</div>

<style>
    .achievements {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .achievement {
        display: flex;
        flex-direction: row;
        margin: 10px;
        display: flex;
        width: 500px;
        height: 70px;
        border: 3px solid gold;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: hidden;
    }
    .achievement:hover {
        animation: glow-border 0.5s infinite alternate;
    }
    @keyframes glow-border {
        0% {
            border-color: gold;
            box-shadow: 0 0 10px gold;
        }
        100% {
            border-color: gold;
            box-shadow: 0 0 20px gold;
        }
    }
    .text {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .heading {
        margin-left: 10px;
        font-size: x-large;
        font-weight: bold;
        color: white;
    }
    .child-text {
        font-weight: bold;
        margin-right: 20px;
        color: white;
        font-size: 13px;
        margin-left: auto;
    }
    h1 {
        display: inline-block;
        position: relative;
        text-shadow: 2px 2px 0 rgba(255, 255, 255, 0.333);
    }
</style>
