<script lang="ts">
	// import "./switch.css"; caused problems on other pages
	// import "./switch.css";
	// import { writable } from "svelte/store";

	// const isChecked = writable(false);
	// const toggleSwitch = (event: Event) => {
	// 	const target = event.target as HTMLInputElement;
	// 	isChecked.set(target.checked);
	// };
	export let isTesting = false;

	export let onChange: (isTesting: boolean) => void;

	$: onChange(isTesting);
</script>

<div class="switch-button">
	<div class="toggle">
		<input type="checkbox" id="btn" bind:checked={isTesting} />
		<label for="btn" class="switch-btn">
			<span class="track">
				<span class="txt">{isTesting ? "Test" : "Learn"}</span>
			</span>
			<span class="thumb">|||</span>
		</label>
	</div>
</div>

<style>
	:root {
		--sz: 20px;
	}
	.switch-button {
		position: relative;
		width: 150px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		left: -50px;
		align-items: center;
	}

	* {
		box-sizing: border-box;
		transition: var(--tr);
	}

	.toggle {
		position: relative;
		width: calc(var(--sz) * 4);
		height: calc(var(--sz) * 1.6);
		left: 10%;
		--on: black;
		--of: black;
		--transition-duration: 0.3s;

		/* height: calc(var(--sz) * 2); */
		top: 5px;
		font-family: Roboto, Arial, Helvetica, serif;
	}

	input {
		display: none;
	}

	label[for="btn"] {
		position: absolute;
		width: calc(var(--sz) * 4);
		height: calc(var(--sz) * 1.5);
		background: linear-gradient(180deg, rgb(255, 255, 0), yellow);
		border-radius: var(--sz);
		padding: calc(var(--sz) / 10);
		box-shadow: 0 0 calc(var(--sz) / 50) calc(var(--sz) / 50) #0002;
	}

	#btn:checked + label[for="btn"] {
		box-shadow:
			0 calc(var(--sz) / 50) calc(var(--sz) / 5) #fff9,
			0 0 calc(var(--sz) / 50) calc(var(--sz) / 50) #0002;
	}

	#btn:checked + label[for="btn"]:after {
		opacity: 1;
		width: calc(100% - calc(calc(var(--sz) / 5) * 4));
	}

	.txt {
		position: absolute;
		width: 100%;
		text-align: center;
	}
	.track {
		position: relative;
		width: calc(calc(var(--sz) * 4.25) - calc(var(--sz) / 2.2));
		height: calc(calc(var(--sz) * 1.7) - calc(var(--sz) / 3));
		background: #f0f0f0;
		border-radius: var(--sz);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
		font-size: calc(var(--sz) * 0.6);
		left: 0;
		top: -1px;
		font-weight: bold;
		color: white;
		overflow: hidden;
		/* left: 2px;
		position: absolute;
		width: calc(calc(var(--sz) * 4.27) - calc(var(--sz) / 2));
		height: calc(calc(var(--sz) * 1.56) - calc(var(--sz) / 2.5));
		border-radius: var(--sz);
		overflow: hidden; */
	}

	.track:before {
		content: "";
		position: absolute;
		width: calc(200% - calc(calc(var(--sz) * 2) - calc(var(--sz) / 3)));
		height: 100%;
		left: -5%;
		font-size: 10px;
		transition: transform 0.3s ease;
		background: black;
		/* animation: move-color 3s ease 0s 1; */
	}

	.track:after {
		left: 0;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: var(--sz);
		box-shadow:
			0 calc(var(--sz) / -10) calc(var(--sz) / 10) 0 #0005 inset,
			0 calc(var(--sz) / 10) calc(var(--sz) / 10) 0 #0005 inset;
	}

	#btn:checked + label .track:before {
		left: 0%;
	}

	.thumb {
		position: absolute;
		width: calc(var(--sz) * 1.4);
		height: calc(var(--sz) * 1.4);
		top: calc(var(--sz) / 20);
		left: calc(var(--sz) / 10);
		background: linear-gradient(180deg, yellow, rgb(157, 157, 0));
		border-radius: var(--sz);
		box-shadow:
			0 0 0 calc(var(--sz) / 40) rgba(0, 0, 0, 0.341),
			0 calc(var(--sz) / 10) calc(var(--sz) / 10) 0 rgba(0, 0, 0, 0.705);
		cursor: pointer;
		font-size: calc(var(--sz) / 2);
		display: flex;
		align-items: center;
		justify-content: center;
		text-indent: calc(var(--sz) * -0.025);
		text-shadow:
			calc(var(--sz) / -8000) calc(var(--sz) / -150) calc(var(--sz) / 50) #0008,
			calc(var(--sz) / 150) calc(var(--sz) / 100) calc(var(--sz) / 50) rgb(157, 157, 0);
		color: black;
		z-index: 1;
		transform: translateX(0);
		transition: transform 0.3s ease;
		/* animation: move-thumb 3s ease 0s 1; */
	}

	#btn:checked + label .thumb {
		transform: translateX(calc(100% - var(--sz)));
		/* transform: translateX(calc(100% - var(--sz))); */
		left: calc(calc(78% - calc(calc(var(--sz) * 2.05) - calc(var(--sz) / 0.8))) - calc(calc(var(--sz) / 8) + calc(var(--sz) / 14)));
		color: var(--on);
		/* background: radial-gradient(circle at 7% 50%, #fff calc(var(--sz) / 50), #fff0 calc(var(--sz) / 2)),
			radial-gradient(circle at 0 50%, var(--on) 10%, #fff0 60%), linear-gradient(180deg, #fff, #afafaf); */
		text-shadow:
			0 0 calc(var(--sz) / 10) var(--on),
			0 0 calc(var(--sz) / 5) #fff,
			calc(var(--sz) / -8000) calc(var(--sz) / -150) calc(var(--sz) / 50) #0008,
			calc(var(--sz) / 150) calc(var(--sz) / 100) calc(var(--sz) / 50) #fff;
	}
	#btn:checked + label .track .txt {
		content: "Test";
		margin-left: -25px;
	}

	.thumb:before {
		content: "";
		display: block;
		width: 70%;
		height: 70%;
		color: black;
		background: linear-gradient(180deg, #0008, rgb(157, 157, 0), rgb(255, 255, 177));
		position: absolute;
		z-index: -1;
		border-radius: var(--sz);
		border: calc(var(--sz) / 50) solid black;
	}

	/* .testing {
		color: white;
		transition: color 0.3s ease;
	}
	.testing2 {
		margin-left: 35%;
		color: white;
		transition: color 0.3s ease;
	}

	.active {
		transition: color 0.3s ease;
	}
	.learn-active {
		color: #00ffc8;
	}

	.test-active {
		color: #2eddf3;
	} */


	/* .txt:before,
.txt:after {
    content: "";
    position: absolute;
    left: calc(var(--sz) / 2);
    top: 25%;
    content: "TESTING"; 
    transition: var(--tr);
    font-size: 10px;
    color: #000;
    opacity: 0.4;
    text-shadow: 0px -1px 1px #000, 0px 1px 2px #fff;
} */
	/* 
.txt:after {
    content: "LEARNING";
    left: calc(100% - calc(var(--sz) / 0.625));
} */
</style>
