<script lang="ts">
    import { onMount, tick } from "svelte";

    export let achievedNewProgressProperty = false; //May be be set to false soon after by the host

    let achievedNewProgress = false;
    let closeButton: HTMLButtonElement;

    $: if (achievedNewProgressProperty) achievedNewProgress = true;

    const closeOverlay = () => {
        achievedNewProgress = false;
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape" || event.key === "Enter") {
            closeOverlay();
        }
    };

    // Watch for changes to `achievedNewProgress`
    onMount(async () => {
        // if (achievedNewProgress) {
        //     await tick(); // Wait for DOM to update
        //     closeButton?.focus(); // Focus `closeButton` if it exists
        // }
    });
</script>

{#if achievedNewProgress}
    <div class="overlay" id="overlay" role="dialog" aria-modal="true" aria-labelledby="dialog-title">
        <h1 id="dialog-title">New game unlocked!</h1>
        <img alt="home" src="/unlocked.svg" class="glow-image" width="150px" height="150px" />
        <h2>Click the home button to see it</h2>
        <button
            bind:this={closeButton}
            on:click={closeOverlay}
            on:keydown={handleKeyDown}
            data-test="close-button"
            class="close-button"
            id="close-button"
            type="button"
        >
            Close
        </button>
    </div>
{/if}

<style>
    /* @import "./global.css"; */

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(0, 0, 0);
        z-index: 9999;
    }

    h2 {
        text-align: center;
        margin: 50px 0 0 0;
    }
    /* #close-button {
        cursor: pointer;
        border: none;
        width: 100px;
        color: white;
    } */
    .glow-image {
        animation: glow 1s infinite alternate;
        border: none;
        border-radius: 50%;
    }

    @keyframes glow {
        0% {
            box-shadow: 0 0 10px 10px gold;
        }
        50% {
            box-shadow: 0 0 20px 15px rgb(255, 255, 0);
        }
        100% {
            box-shadow: 0 0 30px 20px rgb(255, 255, 255);
        }
    }
</style>
