<script lang="ts">
    import Trophy from "./Trophy.svelte";

    export let onOk: () => void;
</script>
<div class="overlay">
    <div id="confetti-wrapper">
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
        <div class="confetti"></div>
    </div>
    <div class="popup">
        <div class="icon-container">
            <div class="checkmark">✔</div>
        </div>
    <h1>Level complete!</h1>
    <button on:click={onOk}>Ok</button>
    </div>
</div>

<style>
    .overlay {
        background-color: rgba(0, 0, 0, 0.818);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    .popup {
        display: flex;
        flex-direction: column;
        background: black;
        border: 1px solid yellow;
        border-radius: 10px;
        padding: 20px 40px;
        text-align: center;
        width: 220px;
        justify-content: center;
        align-items: center;
        z-index: 10;
        animation: zoomOut 1s ease, fadeLight 3s ease-out 0.2s; 
    }

    @keyframes zoomOut {
        0% {
            transform: scale(0); 
            opacity: 0; 
        }
        60% {
            transform: scale(1.1); 
            opacity: 1; 
        }
        100% {
            transform: scale(1); 
        }
    }
    @keyframes fadeLight {
        0% {
            box-shadow: 0 0 100px rgba(255, 255, 148, 0.8);
        }
        100% {
            box-shadow: 0 0 0 rgba(255, 255, 0, 0); 
        }
    }
    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 0 auto 20px;
        background-color: yellow;
        border-radius: 50%;
    }

    .checkmark {
        font-size: 24px;
        color: black;
    }

    h1 {
        padding: 0 0 50px 0;
        margin: 0;
        font-size: 24px;
        color: white;
    }

/* confetti */
#confetti-wrapper {
        overflow: hidden;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .confetti {
        width: 15px;
        height: 15px;
        background-color: #f2d74e;
        position: absolute;
        left: 50%;
        animation: confetti 5s ease-in-out -2s infinite;
        transform-origin: left top;
    }

    .confetti:nth-child(1) {
        background-color: #fcf6b0;
        left: 10%;
        animation-delay: 0;
    }

    .confetti:nth-child(2) {
        background-color: #78531e;
        left: 20%;
        animation-delay: -5s;
    }

    .confetti:nth-child(3) {
        background-color: #e3c47a;
        left: 30%;
        animation-delay: -3s;
    }

    .confetti:nth-child(4) {
        background-color: #896c41;
        left: 40%;
        animation-delay: -2.5s;
    }

    .confetti:nth-child(5) {
        background-color: #9a5f22;

        left: 50%;
        animation-delay: -4s;
    }

    .confetti:nth-child(6) {
        background-color: #ddc571;
        left: 60%;
        animation-delay: -6s;
    }

    .confetti:nth-child(7) {
        background-color: #8a5e25;
        left: 70%;
        animation-delay: -1.5s;
    }

    .confetti:nth-child(8) {
        background-color: #fcdf73;
        left: 80%;
        animation-delay: -2s;
    }

    .confetti:nth-child(9) {
        background-color: #4f3a20;
        left: 90%;
        animation-delay: -3.5s;
    }

    .confetti:nth-child(10) {
        background-color: #f0e9a0;
        left: 100%;
        animation-delay: -2.5s;
    }

    .confetti:nth-child(11) {
        background-color: #f0e9a3;
        left: 72%;
        animation-delay: -5.5s;
    }

    .confetti:nth-child(12) {
        background-color: #f0e4a0;
        left: 34%;
        animation-delay: -2.8s;
    }

    .confetti:nth-child(13) {
        background-color: #f0c9a0;
        left: 100%;
        animation-delay: -7.5s;
    }

    .confetti:nth-child(14) {
        background-color: yellow;
        left: 16%;
        animation-delay: -8.5s;
    }

    @keyframes confetti {
        0% {
            transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
        }
        25% {
            transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 10vh);
        }
        50% {
            transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 30vh);
        }
        75% {
            transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 60vh);
        }
        100% {
            transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 90vh);
        }
    }
</style>
