<script lang="ts">
    import { BatchLessonData, BatchMemory } from "../models/interfaces";
    import GateComponent from "./GateComponent.svelte";
    import BucketMatrix from "./BucketMatrix.svelte";
    //import MemoryTestView from "./MemoryTestView.svelte";

    //Have staging and those in the gates
    export let Memories: BatchMemory[] = [];

    export let onReadyChange: (id: number, readyCount: number) => void;

    //Think it would be better to say like the
    // gateComponentId ==-100 : new
    // gateComponentId == 100 : memorized
    let newCount = 0;
    let memorizedCount = 0;
    let stagingCount = 0;

    import { WaitTimes } from "./WaitTimes";

    let MemoriesList: BatchMemory[][] = [];

    for (let i = 0; i < 20; i++) MemoriesList.push([]); //the first pixels

    let memIndex = 0;

    $: if (Memories) {
        MoveMemory();
    }

    // function onReadyChange(id: number, readyCount: number) {
    //     console.log("onReadyChange");
    // }

    function MoveMemory() {
        stagingCount = 0;
        newCount = 0;
        memorizedCount = 0;

        //console.log(`MoveMemory:`, Memories);
        MemoriesList = [];
        for (let i = 0; i < 20; i++) {
            MemoriesList.push([]);
        }

        Memories.forEach((mem) => {
            //console.log("MemIndex=", memIndex, mem);

            if (mem.gateComponentId == -100) {
                newCount++;
            } else if (mem.gateComponentId == 0) {
                stagingCount++;
            } else if (mem.gateComponentId == 100) {
                memorizedCount++;
            } else {
                MemoriesList[mem.gateComponentId].push(mem);
            }
        });

        // Reassign MemoriesList references to trigger reactivity
        for (let i = 0; i < MemoriesList.length; i++) {
            MemoriesList[i] = [...MemoriesList[i]]; // Create a new reference
        }

        //console.log("MemoriesList", MemoriesList);
    }
</script>

<!-- <h4>staging: {stagingCount}</h4> -->
<div class="memory-container">
    <div class="memory-task left">
        <div class="gate-container">
            {#if newCount}
                <span class="gate-number">New</span>
                <BucketMatrix Waiting={newCount} />
            {:else}
                <div class="memory-empty"></div>
            {/if}
        </div>
        <div class="gate-container">
            <span class="gate-number">Staging</span>
            <BucketMatrix Waiting={stagingCount} />
        </div>
        <GateComponent Id={1} {onReadyChange} Memories={MemoriesList[1]} GateTime="1min" Seconds={WaitTimes(1)} WaitingLaneSide="LEFT" />
        <GateComponent Id={2} {onReadyChange} Memories={MemoriesList[2]} GateTime="2min" Seconds={WaitTimes(2)} WaitingLaneSide="LEFT" />
        <GateComponent Id={3} {onReadyChange} Memories={MemoriesList[3]} GateTime="5min" Seconds={WaitTimes(3)} WaitingLaneSide="LEFT" />
        <GateComponent Id={4} {onReadyChange} Memories={MemoriesList[4]} GateTime="10min" Seconds={WaitTimes(4)} WaitingLaneSide="LEFT" />
    </div>

    <div class="memory-task right">
        <GateComponent Id={10} {onReadyChange} Memories={MemoriesList[10]} GateTime="12h" Seconds={WaitTimes(10)} WaitingLaneSide="RIGHT" />
        <GateComponent Id={9} {onReadyChange} Memories={MemoriesList[9]} GateTime="6h" Seconds={WaitTimes(9)} WaitingLaneSide="RIGHT" />
        <GateComponent Id={8} {onReadyChange} Memories={MemoriesList[8]} GateTime="3h" Seconds={WaitTimes(8)} WaitingLaneSide="RIGHT" />
        <GateComponent Id={7} {onReadyChange} Memories={MemoriesList[7]} GateTime="1.5h" Seconds={WaitTimes(7)} WaitingLaneSide="RIGHT" />
        <GateComponent Id={6} {onReadyChange} Memories={MemoriesList[6]} GateTime="40min" Seconds={WaitTimes(6)} WaitingLaneSide="RIGHT" />
        <GateComponent Id={5} {onReadyChange} Memories={MemoriesList[5]} GateTime="20min" Seconds={WaitTimes(5)} WaitingLaneSide="TOP" />
    </div>
    <div class="memory-task thirdrow-left">
        <GateComponent Id={11} {onReadyChange} Memories={MemoriesList[11]} GateTime="24h" Seconds={WaitTimes(11)} WaitingLaneSide="TOP" />
        <GateComponent Id={12} {onReadyChange} Memories={MemoriesList[12]} GateTime="2d" Seconds={WaitTimes(12)} WaitingLaneSide="LEFT" />
        <GateComponent Id={13} {onReadyChange} Memories={MemoriesList[13]} GateTime="4d" Seconds={WaitTimes(13)} WaitingLaneSide="LEFT" />
        <GateComponent Id={14} {onReadyChange} Memories={MemoriesList[14]} GateTime="1w" Seconds={WaitTimes(14)} WaitingLaneSide="LEFT" />
        <GateComponent Id={15} {onReadyChange} Memories={MemoriesList[15]} GateTime="2w" Seconds={WaitTimes(15)} WaitingLaneSide="LEFT" />
        <GateComponent Id={16} {onReadyChange} Memories={MemoriesList[16]} GateTime="1m" Seconds={WaitTimes(16)} WaitingLaneSide="LEFT" />
    </div>
    <div class="memory-task fourthrow-right">
        <div class="memory-empty"></div>
        <div class="memory-empty"></div>
        <div class="memory-empty"></div>
        <div class="gate-container">
            <span class="memorized">Memorized</span>
            <div class="stage-line">
                <BucketMatrix Waiting={memorizedCount} />
                <div class="line"></div>
            </div>
        </div>
        <GateComponent Id={19} {onReadyChange} Memories={MemoriesList[19]} GateTime="1yr" Seconds={WaitTimes(19)} WaitingLaneSide="RIGHT" />
        <GateComponent Id={18} {onReadyChange} Memories={MemoriesList[18]} GateTime="5m" Seconds={WaitTimes(18)} WaitingLaneSide="RIGHT" />
        <GateComponent Id={17} {onReadyChange} Memories={MemoriesList[17]} GateTime="2m" Seconds={WaitTimes(17)} WaitingLaneSide="TOP" />
    </div>
</div>

<style>
    .memory-container {
        display: grid;
        position: relative;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(4, 1fr);
        align-items: center;
        width: 300px;
        height: auto;
        padding: 30px 0 0 0;
        gap: 10px;
    }
    .gate-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: 15%;
    }
    .gate-number,
    .memorized {
        position: relative;
        top: 0;
        left: 10%;
        font-size: 9px;
        color: white;
    }
    .memory-task {
        display: flex;
        padding: 1px 0 0 0;
        flex-direction: row;
        height: 48.5px;
        align-items: center;
    }
    .memory-empty {
        width: 60px;
    }
    .memory-task.left {
        grid-row: 1;
    }

    .stage-line {
        display: flex;
        align-items: center;
        left: 100px;
    }
    .line {
        margin-right: -49px;
        width: 50px;
        height: 3px;
        background-color: rgba(128, 128, 128, 0.66);
    }
    /* .gate::before {
        content: "";
        width: 35px;
        height: 30px;
    }

    .gate::after {
        content: "";
        width: 35px;
        height: 30px;
        position: absolute;
        background-color: yellow;
        background-image: linear-gradient(to right, black .5px, transparent 0px), linear-gradient(to bottom, black .5px, transparent 0px);
        background-size: 3px 3px; 
    } */
    .memory-task.right {
        grid-row: 2;
    }
    .thirdrow-left {
        grid-row: 3;
    }
    .fourthrow-right {
        grid-row: 4;
        padding-right: 14%;
        margin-top: 0.1px;
    }
</style>
