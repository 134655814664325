import { toast } from "@zerodevx/svelte-toast";

const activeToasts = new Map<string, number>(); // Map to store message and toast ID

export function showError(message: string) {
  // Check if the message is already being displayed
  if (activeToasts.has(message)) return;

  // Show the toast and store the ID
  const toastId = toast.push(message, {
    classes: ["error"],
    dismissable: true, // Adds a close button
    initial: 0,
    onpop: () => {
      // Remove the message from the active map when the toast is dismissed
      activeToasts.delete(message);
    },
  });

  activeToasts.set(message, toastId);
}

export function clearError(message: string) {
  // Check if the message exists in active toasts
  const toastId = activeToasts.get(message);
  if (toastId !== undefined) {
    toast.pop(toastId); // Remove the toast programmatically
    activeToasts.delete(message); // Clean up the map
  }
}