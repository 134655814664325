<script lang="ts">
    import { DateUtc } from "../services/DateUtc";
  import { secondTimer } from "../services/stores";
  export let StartTime: number = 0;
  export let timerRunning = false;

  let oldSeconds = 0;
  let Seconds = 0;

  $: if (timerRunning && $secondTimer) {
    //oldSeconds = $secondTimer;
    //Seconds = $secondTimer - StartTime;
    Seconds = Math.floor((DateUtc.utcNow() - StartTime)/1000);
    //Seconds++;

    //console.log("Seconds: " + Seconds);
  }
</script>

<div>
  <div>
    {#if timerRunning}
    {Seconds}s
    {/if}
  </div>
</div>
