<script lang="ts">
export  let available = -1;
</script>

{#if available !== 0}
<div class="thought-bubble">{available}</div>
{/if}

<style>
    .thought-bubble{
        border: 1px solid rgb(100, 100, 100);
        font-size: 11px;
        width: 30px;
        height: 20px;
        background-color:  #ffffff;
        border-radius: 50%;
        position: relative;
        z-index:50;
    }
    .thought-bubble::before,
    .thought-bubble::after{
        content: '';
        background-color: #fffefe;
        border: 1px solid rgb(100, 100, 100);
        position: absolute;
        border-radius: 50%;
    }
    .thought-bubble::before{
        width: 6px;
        height: 4px;
        bottom: -4px;
        left: 3px;
        z-index: -99;
    }
    .thought-bubble::after{
        width: 3px;
        height: 3px;
        bottom: -8px;
        left: -1px;
    } 
</style>