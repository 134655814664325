<script lang="ts">
    import { navigate, Link } from "svelte-routing";
    import { clickOutside } from "../clickOutside";
    import { isAuthenticated } from "../services/stores";

    export let hamburger: boolean = true;
    let list: boolean = false;

    export let isAdmin = true;
    function toggleList(event: MouseEvent): void {
        event.stopPropagation(); // Stop the click event from propagating
        list = !list;
    }

    function select(menu: string) {
        list = false;
        navigate(menu);
    }

    function toggleMenu(): void {
        list = !list;
    }

    function closeMenu(): void {
        list = false;
    }
</script>

{#if hamburger}
    <div class="menubutton">
        <button on:click={toggleList} class="hamburger-button custom-button" aria-label="Open main menu">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                role="button"
                tabindex="0"
                width="50"
                height="50"
                color="yellow"
                aria-label="bars 3"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
            >
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
        </button>
    </div>
{/if}

{#if list}
    <div use:clickOutside={{ enabled: list, callback: closeMenu }} class="menu-list">
        <button on:click={() => select("/home")} class="menu-item">Home</button>
        <button on:click={() => select("/createapplied")} class="menu-item">Create Applied problem</button>
        {#if isAdmin}
            <button on:click={() => select("/problemlist")} class="menu-item">Problem list</button>
        {/if}
        <button on:click={() => select("/statistic")} class="menu-item" id="menu-statistic">Statistic</button>
        <button on:click={() => select("/achievement")} class="menu-item">Achievements</button>
        <button on:click={() => select("/settings")} class="menu-item">Setting</button>
        <button on:click={() => select("/landing")} class="menu-item">About Us</button>
        {#if $isAuthenticated}
            <button on:click={() => select("/login/logout")} class="menu-item">Sign Out</button>
        {:else}
            <button on:click={() => select("/landing")} class="menu-item">Login/Sign up</button>
        {/if}
        <button on:click={() => select("/payment")} class="menu-item infinite">
            <span class="infiniteSymbol">∞</span> Infinite Play
        </button>
    </div>
{/if}

<style>
    .hamburger-button {
        position: relative;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
    }
    svg:focus,
    .menubutton:focus,
    .custom-button:focus,
    .hamburger-button:focus {
        outline: none;
    }
    button {
        height: 50px;
        width: 50px;
    }
    .menu-item {
        background: white;
        color: black;
        height: 50px;
        width: 295px;
        border: 3px solid gold;
        position: relative;
        overflow: hidden;
    }
    .menu-item:hover {
        color: goldenrod;
        animation: glow-border 0.5s infinite alternate;
    }
    .menu-list {
        display: flex;
        flex-direction: column;
        background: black;
        position: absolute;
        border-radius: 10px;
        top: 60px;
        left: 0;
        width: 310px;
        height: 400px;
        font-size: 20px;
        z-index: 11;
        align-items: center;
    }
    @keyframes glow-border {
        0% {
            border-color: gold;
            box-shadow: 0 0 10px gold;
        }
        100% {
            border-color: gold;
            box-shadow: 0 0 20px gold;
        }
    }
    .infinite {
        display: flex;
        background: white;
        color: black;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 295px;
    }
    .infinite:hover {
        color: goldenrod;
    }
    .infiniteSymbol {
        font-size: 2.5em;
        margin-right: 10px;
        margin-bottom: 10px;
    }
</style>
