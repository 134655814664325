<script lang="ts">
    export let Waiting: number = 0;

    $: gridSize = Math.ceil(Math.sqrt(Waiting));

    $: totalCells = gridSize * gridSize;
</script>

    
    <div class="frame">
        <div class="matrix" style="--grid-size: {gridSize}">
            {#each Array(totalCells) as _, index}
                <div class="cell {index < Waiting ? 'active' : ''}"></div>
            {/each}
        </div>
    </div>

<style>
    .frame {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        padding: 10px;
        margin-left: 10px;
        border: 1px solid yellow;
        background-color: black;
        box-sizing: border-box;
    }

    .matrix {
        display: grid;
        grid-template-columns: repeat(var(--grid-size), 3px);
        grid-template-rows: repeat(var(--grid-size), 3px);
        gap: 1px;
    }

    .cell {
        width: 3px;
        height: 3px;
        background-color: black;
    }

    .cell.active {
        background-color: yellow;
    }
</style>
