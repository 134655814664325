export function WaitTimes(GateIndex: number): number {
    switch (GateIndex) {
        case 1:
            return 60; // 1min
        case 2:
            return 2 * 60; // 2min
        case 3:
            return 5 * 60; // 5min
        case 4:
            return 10 * 60; // 10min
        case 5:
            return 20 * 60; // 20min
        case 6:
            return 40 * 60; // 40min
        case 7:
            return Math.floor(3600 * 1.5); // 1.5h
        case 8:
            return 3 * 3600; // 3h
        case 9:
            return 6 * 3600; // 6h
        case 10:
            return 12 * 3600; // 12h
        case 11:
            return 24 * 3600; // 24h
        case 12:
            return 2 * 24 * 3600; // 2d
        case 13:
            return 4 * 24 * 3600; // 4d
        case 14:
            return 7 * 24 * 3600; // 1w
        case 15:
            return 14 * 24 * 3600; // 2w
        case 16:
            return 30 * 24 * 3600; // 1 month
        case 17:
            return 2 * 30 * 24 * 3600; // 2 months
        case 18:
            return 5 * 30 * 24 * 3600; // 5 months
        case 19:
            return 12 * 30 * 24 * 3600; // 12 months
        default:
            return 3600 * 24 * 365 * 100; // 100 years, default
    }
}