<script lang="ts">
    import "./Home.css";
    import { Link } from "svelte-routing";
    import { TaskInterfaceVm } from "../models/interfaces";
    import AvailableMemory from "./AvailableMemory.svelte";
    //import { tooManyAvailableMemories } from "../services/stores";

    export let taskNumber: number;
    export let progress: number;
    export let memoryTaskTable: TaskInterfaceVm[];

    let title = "";
    let url = "";
    let achieved = 100;
    let available = -1;

    let tooManyAvailableMemories = false;

    function callbackfn(prev: TaskInterfaceVm, cur: TaskInterfaceVm): TaskInterfaceVm {
        let result = new TaskInterfaceVm();
        result.title = "sum so far";

        result.available = prev.available + cur.available;
        //console.log(`prev: ${prev.title} ${prev.available} current: ${cur.title} ${cur.available}`);
        return result;
    }

    $: if (memoryTaskTable && taskNumber <= memoryTaskTable.length) {
        title = memoryTaskTable[taskNumber - 1].title ?? "";
        url = "keypadmem/" + memoryTaskTable[taskNumber - 1].url;
        available = memoryTaskTable[taskNumber - 1].available;

        let sumAvailable = memoryTaskTable.reduce(callbackfn);
        //console.log(`tasknumber ${taskNumber} title: ${title} memoryTaskTable.length() ${memoryTaskTable.length}`);;

        if (sumAvailable.available > 10) {
            tooManyAvailableMemories = true;
            //url = "keypadmem/" + memoryTaskTable[taskNumber - 1].url;
        }
        //else {
        //    tooManyAvailableMemories = false;
        //}
    }
</script>

{#if progress >= taskNumber && (!tooManyAvailableMemories || available > 0)}
    <div class="memo-intro">
        <div class="memory-yellow-button">
            <Link to={url} data-toggle="collapse" data-target=".navbar1-collapse" class="menu-link">
                <div class="small-circle button memory-yellow twobytwo" {title}>
                    {#if achieved == 10}
                        <div class="gld circle"></div>
                    {:else if achieved == 20}
                        <div class="slvr circle"></div>
                    {:else if achieved == 30}
                        <div class="brnz circle"></div>
                    {/if}
                </div>
            </Link>
            <div class="bubble">
                <AvailableMemory {available}></AvailableMemory>
            </div>
        </div>
        <span class="title">{title}</span>
    </div>
{:else}
    <div class="small-circle button gray" {title}></div>
{/if}

<style>
    .memo-intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 30px;
        gap: 5px;
    }
    .bubble {
        position: absolute;
        margin-left: 30%;
        margin-top: -55%;
    }
    .title {
        font-size: 9px;
        font-weight: bold;
        color: black;
        z-index: 10;
        width: auto;
    }
    .circle {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-decoration: none;
        overflow: hidden;
        z-index: 2;
    }
    .circle::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
    }
    .slvr {
        border: 1px solid rgba(255, 255, 255, 0.247);
    }
    .slvr::before {
        background: linear-gradient(#ede8e8, #c2c2c2, #aeaeae, #9a9595, #e0dede);
    }
    .gld {
        border: 1px solid rgba(255, 221, 27, 0.324);
    }
    .gld::before {
        background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffff95);
    }
    .brnz {
        border: 1px solid rgba(255, 255, 255, 0.274);
    }
    .brnz::before {
        background: linear-gradient(#ffd8d8, #e8b094, #d67650, #da8f69, #ffd2d2);
    }
</style>
