<script lang="ts">
    //Sounds.svelte
    import { onMount } from "svelte";
    import type { SocketAddress } from "net";
    import { writable } from "svelte/store";
    import { volume } from "../services/stores";
    import InfiniteButton from "../Payment/InfiniteButton.svelte";
    import { SettingsVm, ContactVm } from "../models/interfaces";
    import { fetchGet } from "../services/fetch";
    //export const userSession = writable<{ isAuthenticated: boolean; userName?: string } | null>(null);

    let isDropdownOpen = false;
    let isMuted = false;
    let isPaid = false;
    let id = 0;

    let settings: SettingsVm | null = null;
    //let volume: number = 100;
    //$: console.log($volume);

    let formattedPaidUntil = "";

    // export function setSession(isAuthenticated: boolean, userName?: string) {
    //     userSession.set({ isAuthenticated, userName });
    // }
    // export function clearSession() {
    //     userSession.set(null);
    // }

    $: if (settings != null) {
        let newDate = new Date(settings?.paidUntil);
        formattedPaidUntil = newDate.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }

    loadSettings();

    async function loadSettings() {
        let url = "/User/GetSettings";
        settings = await fetchGet<SettingsVm>(url);
        isPaid = settings.hasPaid;
        id = settings.id;

        

        //we should also make sure to save the user, in case we cheated, so the payment button goes away
        let loggedInUser = localStorage.getItem("user");
        console.log(`user:${loggedInUser}`);
        
        
        if (loggedInUser) {
            let user: ContactVm = JSON.parse(loggedInUser);

            if(isPaid && !user.hasSubscription) { //inconcistency
                user.hasSubscription = settings.hasPaid;
                localStorage.setItem("user", JSON.stringify(user));
                location.reload();
            }
        }
    }

    function toggleSound(): void {
        isMuted = !isMuted;
        if (isMuted) {
            $volume = 0;
        } else {
            $volume = 0.5;
        }
    }
    function adjustVolume(event: Event): void {
        const volumeSlider = event.target as HTMLInputElement;
        $volume = parseFloat(volumeSlider.value);
        if ($volume === 0) {
            isMuted = true;
        } else {
            isMuted = false;
        }
    }

    function toggleDropdown() {
        isDropdownOpen = !isDropdownOpen;
    }
</script>

<div class="settings-page">
    <h2>Settings</h2>

    <div class="profile-dropdown">
        <div
            class="menu-title"
            on:click={toggleDropdown}
            on:keydown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                    toggleDropdown();
                }
            }}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
            role="button"
            tabindex="0"
        >
            <h3>Profile</h3>
            <div class="arrow">&#x25BC;</div>
        </div>

        {#if !isDropdownOpen}
            <div class="dropdown">
                <div>
                    <span>Id:</span>
                    <div class="id">{id}</div>
                </div>
                <div>
                    <span>Name:</span>
                    <div class="name">
                        {settings?.name}
                    </div>
                </div>

                {#if isPaid}
                    <span>Payment Expiration Date:</span>
                    <!-- <div class="expiration-date">November 20, 2025</div> -->
                    <div class="expiration-date">{formattedPaidUntil}</div>
                {:else}
                    <span>Click to Get:</span>
                    <InfiniteButton></InfiniteButton>
                {/if}
            </div>
        {/if}
    </div>
    <div class="volume-settings" id="volumeControl" style="display: block;">
        <h3 class="title">Sound:</h3>
        <button id="soundToggle" type="button" on:click={toggleSound} aria-label={isMuted ? "Unmute sound" : "Mute sound"}>
            <img alt="Sound Toggle" src={isMuted ? "/mute.svg" : "/unmute.svg"} width="30px" height="30px" />
        </button>
        <input type="range" id="volumeSlider" min="0" max="1" step="0.01" bind:value={$volume} on:input={adjustVolume} />
    </div>
    <!-- <h3>Language</h3>
    <h3>Privacy Policy</h3>
    <h3>Level Select</h3>
    <h3>Invite Friends</h3> -->
</div>

<style>
    #soundToggle {
        width: 30px;
        height: 30px;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }
    .settings-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 400px;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .volume-settings {
        margin: 10px 0;
    }
    input[type="range"]::-webkit-slider-runnable-track {
        width: 200px;
    }
    input[type="range"] {
        vertical-align: middle;
        margin-bottom: 22px;
    }
    .title {
        display: flex;
    }
    /* dropdown */
    .profile-dropdown {
        display: inline-block;
        align-items: flex-start;
        cursor: pointer;
        text-align: left;
    }

    .menu-title {
        display: flex;
        flex-direction: row;
        font-size: 16px;
    }

    .arrow {
        font-size: 12px;
        margin-left: 4px;
        margin-top: 23px;
    }

    .dropdown {
        margin-top: 8px;
        background-color: black;
        border: 1px solid yellow;
        list-style-type: none;
        width: 180px;
    }

    .dropdown,
    .id,
    .name,
    .expiration-date {
        padding: 8px;
        text-align: start;
        color: white;
    }
    .expiration-date {
        display: flex;
        flex-direction: column;
    }

    span {
        font-size: 10px;
        color: #9c9c9c;
    }

    h3 {
        margin-top: 16px;
    }
    .name {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
