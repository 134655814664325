<script lang="ts">
    export let memorized: number;
    export let staged: number;
    export let onOk: () => void;

    const handleOkClick = () => {
        if (onOk) {
            onOk();
        }
    };
</script>

<div class="list-content">
    <div class="popup">
        <p id="memorized-item"> You have memorized {memorized} items.</p>
        <p id="learn-item">Now you may continue to learn {staged} items.</p>
        <button id="outro-ok" on:click={handleOkClick}>OK</button>
    </div>
</div>

<style>
    .list-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.826);
        z-index: 20000;
    }
    .popup{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        background: black;
        border: 1px solid yellow;
        height: 200px;
        width: 300px;
    }
    p {
        padding: 5px;
        text-align: center;
        margin: 0 0 10px 0;
    }
    button{
        margin: 10px 0 0  0;
    }
</style>
