<script lang="ts">
    import { BatchLessonData, BatchMemory } from "../models/interfaces";
    import { secondTimer } from "../services/stores";

    export let Id: number = -1;
    export let GateTime = "1m";
    export let WaitingLaneSide = "";
    export let Seconds: number = 1;
    export let Memories: BatchMemory[] = [];
    export let onReadyChange: (id: number, readyCount: number) => void; // New callback prop

    let Pixels: number[] = [];
    //Pixels = [0.1, 0.5, 0.75];

    let ready: number = 0;


    $: if (Pixels) {
        //console.log(`Id=${Id} Seconds=${Seconds} pixels=${Pixels}`);
    }

    $: if (Memories) {
        let oldReady = ready;
        ready = 0;
        Pixels = [];

        Memories.forEach((mem) => {
            if (mem.secondsRemembered > Seconds) {
                ready++;
            } else {
                let percent = mem.secondsRemembered / Seconds;
                Pixels.push(percent);

                //console.log(`At GateId=${Id} Pushing pixel percent=${percent}`);
            }
        });
        //console.log(`At GateId=${Id} Ready=${ready}`);

        if (ready != oldReady) {
            // Notify Keypadmem about the ready count
            console.log(`Gate with Id ${Id} changed ready to ${ready}`);
            onReadyChange(Id, ready);
        }
    }

    $: if ($secondTimer) {
        //console.log(`secondTimer=${$secondTimer}`);

        Memories.forEach((mem) => {
            mem.secondsRemembered++;
        });

        // Reassign Memories to trigger reactivity
        Memories = [...Memories];
    }
</script>

<div class="testing">
<!-- <div style="font-size: 10px;">Count: {Memories.length}</div>
<div style="font-size: 10px;">ready {ready}</div>
<div style="font-size: 10px;">seconds {Seconds}</div> --> 
<!-- <div>Ready: {ready}</div> -->

<div class="line-gate">
    <div class="line {WaitingLaneSide}">
        {#each Pixels as pixel}
            <div class="pixel" style="left: calc({pixel} * 100%)"></div>
        {/each}
    </div>
    <div class="gate-container">
        <span class="gate-number">{GateTime}</span>
        <div id={"gate-" + Id} class="gate {ready > 0 ? 'gate-glow active' : ''}">
            {#if ready > 0}
                {ready}
            {/if}
        </div>
    </div>
   
</div>
</div>

<style>
    .testing{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .LEFT {
        left: -50px;
        top: 0;
        transform: rotate(0deg);
    }
    .RIGHT {
        position: relative;
        right: -60px;
        top: 0;
        transform: rotate(180deg);
    }
    .TOP {
        position: relative;
        margin-top: -60px;
        left: 50%;
        transform: rotate(90deg);
    }
    .BOTTOM {
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
    }
    .pixel {
        position: absolute;
        width: 3px;
        height: 3px;
        background-color: yellow;
    }
    .line-gate {
        display: flex;
        width: 60px;
        align-items: center;
        flex-direction: row;
        position: relative;
    }
    .line {
        width: 50px;
        height: 3px;
        background-color: rgba(128, 128, 128, 0.66);
    }

    .gate-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gate-number {
        position: absolute;
        top: -15px;
        font-size: 9px;
        color: white;
    }
    .gate {
        width: 10px;
        height: 10px;
        font-size: 5px;
        border: 1px solid yellow;
        background-color: none;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }

    /* .gate::before {
        content: "";
        width: 4px;
        height: 3px;
        background-color: white;
        z-index: 10;
    } */
    .gate-glow {
        box-shadow: 0 0 10px 3px rgb(251, 255, 191);
        animation: glow 1s infinite alternate;
        z-index: -1;
    }

    .gate-glow::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: radial-gradient(circle, rgb(255, 255, 255), transparent);
        animation: smoke-spread 2s infinite;
        opacity: 0;
        pointer-events: none;
        z-index: -1;
    }
    @keyframes glow {
        from {
            box-shadow: 0 0 10px 3px rgb(251, 255, 191);
        }
        to {
            box-shadow: 0 0 20px 3px rgb(251, 255, 191);
        }
    }

    @keyframes smoke-spread {
        0% {
            transform: scale(0.5);
            opacity: 0.5;
        }
        100% {
            transform: scale(2.5);
            opacity: 0;
        }
    }
</style>
